<template>
  <div id="business-delivery" class="business-delivery">
    <AStatic :content="content" ref="businessDelivery" />
  </div>
</template>

<script>
import AStatic from 'theme/components/atoms/a-static';
import AFaq from 'theme/components/atoms/a-faq';
import customFormSpinnerLoading from 'theme/mixins/CustomFormSpinnerLoading'
import Vue from 'vue'
import GoogleTagManager from 'theme/mixins/gtm';

export default {
  name: 'BusinessDelivery',
  mixins: [customFormSpinnerLoading, GoogleTagManager],
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic
  },
  data () {
    return {
      faqTitle: '',
      faqs: [],
      banner: {},
      elSubmit: null
    }
  },
  methods: {
    /**
     * Mapping the content value from Magento admin panel (CMS Page) on VS component - SfAccordion
     */
    mapAccordion () {
      try {
        const accordion = this.$refs?.businessDelivery?.$el?.querySelector('#accordion');

        if (!accordion) return

        const title = accordion?.previousElementSibling?.classList?.contains('sf-heading__title')
          ? accordion?.previousElementSibling : null

        this.faqTitle = title?.innerText || ''

        if (title) {
          title.classList.add('display-none')
        }

        if (!accordion) {
          return '';
        }
        let accordionItems = accordion.querySelectorAll('.accordion-item');

        const items = [...accordionItems].map((el, i) => {
          const accordionItem = {
            id: i,
            title: this.$t(el.querySelector('.title')?.textContent || ''),
            text: el.querySelector('.accordion-item-text')?.innerHTML || ''
          };

          return accordionItem
        })

        this.faqs = items

        // Replace the div with your Vue component
        if (accordion) {
          const component = new Vue({
            render: h => h(AFaq, {
              props: {
                faqTitle: this.faqTitle,
                faqs: this.faqs
              }
            })
          }).$mount();

          // Replace the div with the rendered component
          accordion.parentNode.replaceChild(component.$el, accordion);
        }
      } catch (e) {}
    },
    getBannerContent () {
      const bannerSection = this.$refs.businessDelivery?.$el?.querySelector('#banner');

      if (!bannerSection) return

      const title = bannerSection?.querySelector('.banner-text')?.textContent;
      const buttonText = bannerSection?.querySelector('.banner-button')?.textContent;
      const image = bannerSection?.querySelector('.images-wrapper img')?.outerHTML;
      const bubbles = bannerSection?.querySelector('.banner-bubbles')?.outerHTML;

      this.banner = {
        title,
        buttonText,
        image,
        bubbles
      }
    },
    sendEvent (e) {
      try {
        if (e?.data !== 'custom_form_event:submit_form') return

        this.gtmOptPurchases()
      } catch (e) {}
    }
  },
  mounted () {
    this.mapAccordion();
    this.getBannerContent();
    window.addEventListener('message', this.sendEvent, false);
  },
  destroyed () {
    window.removeEventListener('message', this.sendEvent, false);

    if (!this.elSubmit) return

    this.elSubmit.removeEventListener('click', () => this.sendEvent())
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";
@import '~theme/css/fonts';

#business-delivery {
  ::v-deep {
    padding-bottom: var(--spacer-50);

    @media (min-width: $tablet-min) {
      padding-bottom: var(--spacer-100);
    }

    .container {
      margin: 0 var(--spacer-10);

      @media (min-width: $tablet-min) {
        max-width: px2rem(990);
        margin: 0 auto;
        min-height: px2rem(250);
      }
    }

    .orange-underline {
      border-bottom: 2px solid var(--orange);
    }

    .orange-text {
      color: var(--orange);
    }

    .content {
      padding: 0;

      & > .sf-heading {
        display: none;
      }
    }

    #accordion {
      display: none;
    }

    .description {
      margin: var(--spacer-27) var(--spacer-29);
      z-index: 1;

      @media (min-width: $tablet-min) {
        margin: var(--spacer-56) var(--spacer-81);
      }

      .banner-text {
        font-weight: 500;
        margin-top: 0;
        font-size: var(--font-size-20);
        line-height: var(--spacer-24);
        width: 100%;
        max-width: 230px;

        @media (min-width: $tablet-min) {
          width: 55%;
          max-width: 100%;
          font-weight: 500;
          font-size: var(--font-size-32);
          line-height: var(--spacer-33);
          color: var(--white);
        }
      }
      .banner-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--white);
        margin: 0;
        height: px2rem(40);
        outline: none;
        border: none;
        cursor: pointer;
        @include for-desktop {
          max-width: px2rem(218);
        }

        span {
          font-style: normal;
          font-weight: 500;
          text-align: center;
          color: var(--black);
          font-size: var(--font-size-14);
          line-height: var(--spacer-24);
        }

        @media (min-width: $tablet-min) {
          min-width: px2rem(271);
          height: px2rem(56);

          span {
            font-size: var(--font-size-18);
            line-height: var(--spacer-24);
          }
        }
      }
    }

    .images-wrapper {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;

      @media (min-width: $tablet-min) {
        margin-right: 0;
        right: 0;
      }

      .banner-img {
        & > img {
          height: 100%;
          position: relative;
        }
      }
    }

    .center, .departments {
      padding: 0 var(--spacer-13);
      max-width: px2rem(790);
      margin: 0 auto;

      @media (min-width: $tablet-min) {
        margin-top: var(--spacer-50);
        padding: 0;
      }
    }

    .title {
      padding-bottom: 0;

      & > h2 {
        @include header-title;
      }
      & > h3 {
        text-align: left;
        font-weight: 500;
        font-size: var(--font-size-16);
        line-height: var(--spacer-19);
        color: var(--black);

        @media (min-width: $tablet-min) {
          font-weight: 500;
          font-size: var(--font-size-24);
          line-height: var(--spacer-25);
        }
      }
    }

    .section-title {
      margin: var(--spacer-30) 0 var(--spacer-15);
      padding-bottom: 0;

      & > h2 {
        font-weight: 500;
        color: var(--black);
        font-size: var(--font-size-20);
        line-height: var(--spacer-24);
        text-align: left;
      }

      @media (min-width: $tablet-min) {
        font-weight: 500;
        margin: var(--spacer-60) 0 var(--spacer-25);
        padding-bottom: 0;
        text-align: left;

        & > h2 {
          font-size: var(--font-size-24);
        }
      }
    }

    .main-information {
      p {
        font-size: var(--font-size-14);
        font-weight: 400;
        line-height: var(--spacer-24);
        margin: 0 0 var(--spacer-10);

        &:first-child {
          margin-top: var(--spacer-20);
        }
      }
    }

    // Get Sale Static Block
    .get-sale-block {
      margin-top: var(--spacer-50);
      background: #FDF0ED;
      padding: var(--spacer-33) var(--spacer-17) var(--spacer-64);

      @media (min-width: $tablet-min) {
        margin-top: var(--spacer-60);
        padding: var(--spacer-48) var(--spacer-24) var(--spacer-55);
      }

      &__title {
        font-style: normal;
        font-weight: 500;
        text-align: center;
        color: var(--black);
        font-size: var(--font-size-16);
        line-height: var(--spacer-19);

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-24);
          line-height: var(--spacer-25);
        }
      }

      &__items {
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (min-width: $tablet-min) {
          flex-direction: row;
          margin: 0 auto;
        }

        &--item {
          justify-content: center;
          text-align: center;
          position: relative;
          margin-bottom: var(--spacer-57);

          &:after {
            content: "";
            border: 2px dashed var(--orange);
            height: 0;
            position: absolute;
            bottom: -40px;
            right: 0;
            left: 0;
            margin: 0 auto;
            transform: rotate(90deg);
            width: px2rem(60);
          }

          @media (min-width: $tablet-min) {
            margin: 0;
            flex: 1 1 calc(100% / 3);

            &:after {
              content: "";
              border: 2px dashed var(--orange);
              height: 0;
              position: absolute;
              top: 40%;
              transform: translateY(-50%);
              right: -20px;
              left: unset;
              width: px2rem(60);
            }
          }

          &-text {
            padding: 0 var(--spacer-20);
            font-weight: 400;
            font-size: var(--font-size-14);
            line-height: var(--spacer-24);
            text-align: center;
            color: var(--black);
          }

          &:last-child {
            margin-bottom: 0;
            &:after {
              display: none;
            }
          }
        }
      }
    }

    .image-wrapper {
      margin-top: var(--spacer-30);
      margin-bottom: var(--spacer-22);

      @media (min-width: $tablet-min) {
        margin-top: var(--spacer-50);
        margin-bottom: var(--spacer-32);
      }

      &-circle {
        width: px2rem(170);
        height: px2rem(170);
        display: flex;
        position: relative;
        border-radius: 50%;
        z-index: 0;
        justify-content: center;
        align-items: center;
        text-align: center;
        left: 0;
        right: 0;
        margin: 0 auto;
        border: 10px solid var(--white);
        box-sizing: border-box;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
      }

      &-number {
        position: absolute;
        width: px2rem(60);
        height: px2rem(60);
        right: 0;
        top: -20px;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--white);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-24);
        line-height: var(--spacer-33);
        text-align: center;
        color: var(--orange);
      }
    }

    // Accumulate Bonuses Static Block

    .accumulate-bonuses-section {
      margin-top: var(--spacer-50);
      position: relative;
      padding-bottom: var(--spacer-33);

      @media (min-width: $tablet-min) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: var(--spacer-60);

        &__wrapper {
          width: 60%;
        }
      }

      .main-information {
        margin-bottom: var(--spacer-20);
      }

      &__image {
        text-align: center;
        position: relative;
        @media (min-width: $tablet-min) {
          width: 40%;
        }
      }

      .varus-sale {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background: var(--white);
        box-shadow: 0 5.31707px 17.2805px rgba(0, 0, 0, 0.06);
        border-radius: px2rem(22);
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: px2rem(200);
        padding: 0 var(--spacer-10) 0 0;

        @media (min-width: $tablet-min) {
          max-width: px2rem(150);
        }

        &__logo {
          width: px2rem(53);
          height: px2rem(53);
          border-radius: 50%;
          position: relative;
          background: var(--orange);
          border: 3.9878px solid var(--white);
          box-sizing: border-box;
          box-shadow: 0 3.9878px 10.6341px rgba(0, 0, 0, 0.08);
          display: flex;
          align-items: center;
          justify-content: center;

          @media (min-width: $tablet-min) {
            width: px2rem(40);
            height: px2rem(40);
            left: 0;

            svg {
              width: px2rem(20);
              height: auto;
            }
          }
        }

        &__text {
          font-weight: 400;
          font-size: var(--font-size-16);
          line-height: var(--spacer-19);
          color: var(--orange);
          display: flex;
          align-items: center;

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-12);
            line-height: var(--spacer-15);
          }

          span {
            font-weight: 500;
            font-size: var(--font-size-21);
            line-height: var(--spacer-26);
            color: var(--orange);
            margin-right: var(--spacer-7);

            @media (min-width: $tablet-min) {
              font-size: var(--font-size-16);
              line-height: var(--spacer-19);
            }
          }
        }
      }
    }

    .loyalty-perfect-cards {
      display: flex;
      margin: 0 auto;
      max-width: 670px;
      flex-wrap: wrap;
      row-gap: 8px;
      column-gap: 4px;
      flex-direction: column;
      align-items: center;

      @media (min-width: $tablet-min) {
        flex-direction: row;
      }
    }

    .bonuses {
      background: #F3F4F6;
      padding: var(--spacer-10) var(--spacer-20) var(--spacer-30);

      @media (min-width: $tablet-min) {
        display: flex;
        align-items: center;
      }

      &__wrapper {
        @media (min-width: $tablet-min) {
          padding: var(--spacer-64) var(--spacer-100) var(--spacer-50) var(--spacer-10);
        }
      }

      &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        img {
          height: px2rem(300);
        }

        @media (min-width: $tablet-min) {
          width: 100%;

          img {
            height: px2rem(400);
          }

        }
      }
      &__percent {
        position: absolute;
        top: var(--spacer-20);
        right: var(--spacer-60);
        width: var(--spacer-90);
        height: var(--spacer-90);
        background: var(--white);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (min-width: $tablet-min) {
          left: 0;
          right: -60px;
          margin: 0 auto;
          width: px2rem(135);
          height: px2rem(135);
        }

        .percent {
          display: block;
          font-style: normal;
          font-weight: 500;
          font-size: var(--font-size-24);
          line-height: var(--spacer-33);
          text-align: center;
          color: var(--black);

          @media (min-width: $tablet-min) {
            font-size: 36px;
            line-height: 38px;
          }
        }

        span {
          font-weight: 500;
          font-size: var(--font-size-12);
          line-height: var(--spacer-33);
          text-align: center;
          color: var(--black);

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-18);
            line-height: var(--spacer-19);
          }
        }
      }

      &__list {
        margin: 0;
        padding: 0 0 0 var(--spacer-20);
        list-style: none;

        @media (min-width: $tablet-min) {
          margin-top: var(--spacer-20);
        }

        li {
          position: relative;
          font-weight: 400;
          font-size: var(--font-size-16);
          line-height: var(--spacer-28);
          color: var(--black);
          margin-top: 10px;
          &:before {
            content: "\2022";
            color: var(--orange);
            font-weight: bold;
            display: inline-block;
            width: px2rem(20);
            margin-left: -20px;
          }
        }
      }
    }

    .accordion {
      max-width: px2rem(790);
      width: 100%;
      margin: 0 auto;
    }

    .sf-accordion-item {
      box-sizing: border-box;
      margin: var(--spacer-10) 0;
      position: relative;

      @media (min-width: $tablet-min) {
        margin: var(--spacer-12) 0;
      }

      .sf-accordion-item__header {
        background: var(--light-gray);
        font-size: var(--font-size-14);
        border: 1px solid transparent;
        line-height: var(--spacer-24);
        justify-content: space-between;
        padding: var(--spacer-15) 0 var(--spacer-15) var(--spacer-15);
        text-align: left;
        padding-right: var(--spacer-40);

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-18);
        }

        @include font-icon(var(--icon-shape));

        &:before {
          position: absolute;
          right: 0;
          font-size: var(--font-size-12);
          margin: var(--spacer-15);
          height: var(--spacer-15);
          transition: transform 0.2s ease;
          width: var(--spacer-15);

          @media (min-width: $tablet-min) {
            margin-right: var(--spacer-36);
          }
        }

        &.sf-accordion-item__header--open {
          background: transparent;
          border: 1px solid var(--gray3);
          border-bottom: none;

          &:before {
            transform: rotate(180deg);
            transition: transform 0.2s ease;
          }
        }

        @media (min-width: $tablet-min) {
          padding: var(--spacer-30) 0 var(--spacer-30) var(--spacer-30);
        }
      }

      .sf-accordion-item__content {
        border: 1px solid var(--gray3);
        border-top: none;
        padding: 0 var(--spacer-26) var(--spacer-15) var(--spacer-15);

        @media (min-width: $tablet-min) {
          padding: 0 var(--spacer-65) var(--spacer-30) var(--spacer-30);
        }

        .accordion-item-text {
          color: var(--black);
          font-weight: 400;
          font-size: var(--font-size-14);
          line-height: var(--spacer-24);

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-16);
            line-height: var(--spacer-28);
          }
        }
      }
    }

    .loyalty-carousel {
      .glide__slide {
        @include for-mobile {
          overflow: hidden scroll;
        }
      }
    }

    .departments,
    .feedback-block,
    .accordion {
      max-width: 790px;
      width: 100%;

      .reception {
        background: var(--light-gray);
        display: flex;
        flex-wrap: wrap;
        margin: 0 var(--spacer-10) var(--spacer-18);
        padding: var(--spacer-30) 0 0 var(--spacer-30);

        @include for-desktop {
          margin-bottom: var(--spacer-50);
        }

        & > span {
          font-size: var(--font-size-14);
          line-height: var(--spacer-16);
          margin-bottom: var(--spacer-15);
          max-width: 360px;
          width: 100%;
          color: var(--dark-gray);
        }

        .reception-telephone {
          color: var(--orange);
          font-size: var(--font-size-18);
          margin-bottom: var(--spacer-30);

          @include for-desktop {
            font-size: var(--font-size-20);
            line-height: var(--spacer-20);
          }
        }
      }
    }

    .departments-title,
    .feedback-title {
      margin: 0 0 var(--spacer-15) var(--spacer-10);
      padding-bottom: 0;
      text-align: left;

      @include for-desktop {
        margin-bottom: var(--spacer-25);
      }
    }

    //.departments {
    //  margin-top: var(--spacer-30);
    //
    //  @include for-desktop {
    //    margin-bottom: var(--spacer-50);
    //  }
    //}

    .center, .departments {
      padding-left: var(--spacer-10);
      padding-right: var(--spacer-10);
    }

    .reception {
      background: var(--light-gray);
      display: flex;
      flex-wrap: wrap;
      margin: 0 var(--spacer-10) var(--spacer-18);
      padding: var(--spacer-30) 0 0 var(--spacer-30);

      @include for-desktop {
        margin-bottom: var(--spacer-50);
      }

      & > span {
        font-size: var(--font-size-14);
        line-height: var(--spacer-16);
        margin-bottom: var(--spacer-15);
        max-width: 360px;
        width: 100%;
        color: var(--dark-gray);
      }

      .reception-telephone {
        color: var(--orange);
        font-size: var(--font-size-18);
        margin-bottom: var(--spacer-30);

        @include for-desktop {
          font-size: var(--font-size-20);
          line-height: var(--spacer-20);
        }
      }
    }

    .content {
      .sf-heading__title {
        @include header-title;
        font-size: var(--font-size-22);
        line-height: var(--spacer-24);
        font-weight: 500;

        @include for-desktop {
          font-size: var(--font-size-32);
        }
      }

      .display-none {
        display: none;
      }
    }
  }

  ::v-deep {
    .static-page__information {
      margin-top: 30px;
    }

    // Banner
    .banner {
      color: var(--white);
      background: var(--static-page-banner-background, #ff9800);
      display: flex;
      /*justify-content: space-between;*/
      min-height: px2rem(150);
      overflow: hidden;
      position: relative;
      margin-bottom: var(--spacer-30);

      &-bubble {
        display: none;
      }

      @media (min-width: $tablet-min) {
        max-width: px2rem(990);
        margin: 0 auto;
        min-height: px2rem(250);

        &-bubble {
          position: absolute;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--orange);
          box-sizing: border-box;
          z-index: 1;

          &.small {
            width: px2rem(64);
            height: px2rem(64);
            border: 3.29037px solid var(--white);
            box-shadow: 0 1.09679px 2.92478px rgba(0, 0, 0, 0.08);
            right: -25px;
            top: 50%;
            transform: translateY(-50%);
            filter: blur(2.5px);

            svg {
              width: px2rem(31);
              height: auto;
            }
          }

          &.medium {
            width: px2rem(82);
            height: px2rem(82);
            border: 4.21027px solid var(--white);
            box-shadow: 0 1.40342px 3.74246px rgba(0, 0, 0, 0.08);
            filter: blur(2.5px);
            top: -40px;
            left: 35%;

            svg {
              width: px2rem(39);
              height: auto;
            }
          }

          &.big {
            width: px2rem(120);
            height: px2rem(120);
            left: 12%;
            right: 0;
            margin: 0 auto;
            bottom: -37px;
            border: 6.19157px solid var(--white);
            box-shadow: 0 2.06386px 5.50361px rgba(0, 0, 0, 0.08);
          }
        }
      }
    }

    .information-2cl {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 49px;

      @media (max-width: $tablet-min) {
        gap: 20px;
      }

      &__block {
        flex: 1 1 calc(50% - 49px);
        display: flex;

        @media (max-width: $tablet-min) {
          flex: 1 1 100%;
        }

        &-image {
          display: block;
          flex: 0 0 170px;

          img {
            display: block;
            width: 170px;
          }
        }

        &-text {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }
      }
    }

    .static-page--underline {
      position: relative;
      padding-bottom: 21px;

      &:before, &:after {
        position: absolute;
        left: 0;
        content: ' ';
      }

      &:before {
        right: 0;
        bottom: -1px;
        height: 1px;
        background: var(--gray-lighter);
      }

      &:after {
        width: 100%;
        max-width: 123px;
        bottom: -2px;
        height: 3px;
        background: var(--orange);
      }
    }
  }
}
</style>
